<template>
  <div class="card-wallet-pass">
    <div class="card-wallet-pass__header">
      <div class="-first">
        <div
          class="card-wallet-pass__icon"
          :class="{ '-is-disabled': isIos }">
          <ui-img
            alt="Android"
            :src="androidLogo"
          />
        </div>

        <div
          class="card-wallet-pass__icon"
          :class="{ '-is-disabled': isAndroid }">
          <ui-img
            alt="IOS"
            :src="appleLogo"
          />
        </div>
      </div>

      <h4 class="card-wallet-pass__title -desktop">{{ $t('user-portal.user_balance_wallet_pass_title') }}</h4>

      <actions-button
        :icon-post="showWalletPass ? 'caret-up' : 'caret-down'"
        :size="$pepper.Size.S"
        @click="showWalletPass = !showWalletPass"
      />
    </div>

    <div class="card-wallet-pass__header -mobile">
      <h4 class="card-wallet-pass__title">{{ $t('user-portal.user_balance_wallet_pass_title') }}</h4>
    </div>

    <div
      class="card-wallet-pass__body"
      v-if="showWalletPass">
      <nav class="card-wallet-pass__devices">
        <div
          class="card-wallet-pass__device device"
          :class="isSelected(device.value)"
          :key="$basil.uniqId(index)"
          v-for="(device, index) in devices"
          @click="onOsChange(device.value)">
          <div class="device__icon">
            <ui-img
              class="device__img"
              :src="logos[device.value]"
              alt=""
            />
          </div>

          <span class="device__label">{{ device.label }}</span>
        </div>
      </nav>

      <div class="card-wallet-pass__content">
        <div v-html="label"></div>

        <div class="card-wallet-pass__download">
          <actions-button
            appearance="primary"
            icon-pre="download"
            @click="onGetWalletPass"
          >{{ $t('user-portal.user_balance_download_pass_action') }}</actions-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '@/config'

import UiImg from '@/components/ui/img'

export default {
  name: 'CardWallet',

  components: {
    UiImg,
  },

  inject: [
    '$core',
    '$user',
    '$embed',
  ],

  data() {
    return {
      detectedOs: null,
      devices: [
        {
          label: this.$t('user-portal.user_balance_wallet_pass_android_name'), //'Android',
          value: 'android'
        },

        {
          label: this.$t('user-portal.user_balance_wallet_pass_ios_name'), //'iOS',
          value: 'ios'
        }
      ],
      os: 'android',
      showWalletPass: false,
    }
  },

  computed: {
    androidLogo() {
      return `${Config.cdn}statics/images/icons/android.svg`
    },

    appleLogo() {
      return `${Config.cdn}statics/images/icons/apple.svg`
    },

    isAndroid() {
      return this.detectedOs === 'android'
    },

    isIos() {
      return this.detectedOs === 'ios'
    },

    label() {
      let ret = this.devices.find(d => d.value === this.os)
      return this.labels[this.$basil.get(ret, 'value', 'android')]
    },

    labels() {
      return {
        android: this.$t('user-portal.user_balance_wallet_pass_android_description'),
        ios: this.$t('user-portal.user_balance_wallet_pass_ios_description'),
      }
    },

    logos() {
      return {
        android: this.androidLogo,
        ios: this.appleLogo
      }
    },

    walletHref() {
      return this.$basil.get(Config, 'transports.cp.api') + `user/card`//?embed_id=${id}`
    }
  },

  methods: {
    isSelected(os) {
      return this.os === os ? '-selected' : ''
    },

    async onGetWalletPass() {
      try {
        this.loadingWallet = true
        let token = await this.$user.getTokenForPkPass({})

        let id = this.$basil.get(this.$embed, 'embed.id')
        window.location.assign(this.walletHref + `/${token}?embed_id=${id}`)
      } catch(e) {
        $console.error(e)
      } finally {
        this.loadingWallet = false
      }
    },

    onOsChange(os) {
      this.os = os
    },

    onShowDevices() {
      this.showWalletPass = true
    },

    onWalletClose() {
      this.showWalletPass = false
    }
  },

  mounted() {
    var MobileDetect = require('mobile-detect'),
    md = new MobileDetect(navigator.userAgent)

    if(md.os() === 'AndroidOS') {
      this.os = 'android'
      this.detectedOs = 'android'
    } else if(md.os() === 'iOS') {
      this.os = 'ios'
      this.detectedOs = 'ios'
    }
  }
}
</script>
